body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: purple;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

html {
  scroll-behavior: smooth; }

body {
  font-family: 'Muli', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  padding-top: 54px;
  color: #868e96; }

@media (min-width: 992px) {
  body {
    padding-top: 0;
    padding-left: 17rem; } }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Saira Extra Condensed', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 700;
  text-transform: uppercase;
  color: #343a40; }

h1 {
  font-size: 6rem;
  line-height: 5.5rem; }

h2 {
  font-size: 3.5rem; }

h3 {
  font-size: 2rem; }

p.lead {
  font-size: 1.15rem;
  font-weight: 400; }

.subheading {
  text-transform: uppercase;
  font-weight: 500;
  font-family: 'Saira Extra Condensed', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1.5rem; }

.social-icons a {
  display: inline-block;
  height: 3.5rem;
  width: 3.5rem;
  background-color: #495057;
  color: #fff !important;
  border-radius: 100%;
  text-align: center;
  font-size: 1.5rem;
  line-height: 3.5rem;
  margin-right: 1rem; }
  .social-icons a:last-child {
    margin-right: 0; }
  .social-icons a:hover {
    background-color: #00b4fb; }

.dev-icons {
  font-size: 3rem; }
  .dev-icons .list-inline-item i:hover {
    color: #00b4fb; }

.bg-primary {
  background-color: #00b4fb !important; }

.text-primary {
  color: #007bff !important; }

a {
  color: #00b4fb; }
  a:hover, a:focus, a:active {
    color: #d9d9d9; }

#sideNav .navbar-nav .nav-item .nav-link {
  font-weight: 800;
  letter-spacing: 0.05rem;
  text-transform: uppercase; }

#sideNav .navbar-toggler:focus {
  outline-color: white; }

@media (min-width: 992px) {
  #sideNav {
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 17rem;
    height: 100vh; }
    #sideNav .navbar-brand {
      display: flex;
      margin: auto auto 0;
      padding: 0.5rem; }
      #sideNav .navbar-brand .img-profile {
        max-width: 10rem;
        max-height: 10rem;
        border: 0.5rem solid rgba(255, 255, 255, 0.2); }
    #sideNav .navbar-collapse {
      display: flex;
      align-items: flex-start;
      flex-grow: 0;
      width: 100%;
      margin-bottom: auto; }
      #sideNav .navbar-collapse .navbar-nav {
        flex-direction: column;
        width: 100%; }
        #sideNav .navbar-collapse .navbar-nav .nav-item {
          display: block; }
          #sideNav .navbar-collapse .navbar-nav .nav-item .nav-link {
            display: block; }
  a {
    color: #fff; }
    a:hover, a:focus, a:active {
      color: white; } }

section.resume-section {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
  max-width: 75rem; }
  section.resume-section .resume-item .resume-date {
    min-width: none; }

@media (min-width: 768px) {
  section.resume-section {
    min-height: 100vh; }
    section.resume-section .resume-item .resume-date {
      min-width: 18rem; } }

@media (min-width: 992px) {
  section.resume-section {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; } }

.bg-primary {
  background-color: #00b4fb !important; }

.text-primary {
  color: #007bff !important; }

a {
  color: #00b4fb; }
  a:hover, a:focus, a:active {
    color: #d9d9d9; }

.app {
  text-align: center; }

.app__logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px; }

.app__header {
  background-color: #00b4fb;
  padding: 20px;
  color: white; }

.app__intro {
  font-size: large; }

.app__list {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  margin: 0 auto; }

.app__button {
  border: 0;
  border-radius: 0;
  padding: 10px 20px;
  color: black;
  background-color: white;
  cursor: pointer;
  opacity: .8;
  outline: 0; }

.app__button:hover {
  opacity: 1; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.form {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 100%; }

.form__row {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  padding: 20px; }

.form__input {
  width: 100%;
  padding: 15px 20px;
  border-radius: 5px 0 0 5px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  outline: 0; }

.form__input:focus {
  background-color: rgba(245, 243, 243, 0.5); }

.form__button {
  padding: 0 30px;
  background-color: #00b4fb;
  color: #fff;
  border: 0;
  border-radius: 0 5px 5px 0;
  outline: 0;
  cursor: pointer; }

.form__button:hover {
  background-color: rgba(0, 0, 0, 0.8); }

.form__message {
  width: 100%;
  padding: 0 20px; }

.message {
  position: relative;
  padding: 10px 60px 10px 20px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0.05);
  text-align: left;
  line-height: 1.7; }

.message:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.025); }

.message__author {
  font-weight: 600;
  margin-right: 10px;
  color: black; }

