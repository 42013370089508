// Global styling for this template
html{
  scroll-behavior: smooth;
}
body {
    @include body-font;
    padding-top: 54px;
    color: $gray-600;
  }
  @media (min-width: 992px) {
    body {
      padding-top: 0;
      padding-left: $sidebar-base-width;
    }
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include heading-font;
    font-weight: 700;
    text-transform: uppercase;
    color: $gray-800;
  }
  
  h1 {
    font-size: 6rem;
    line-height: 5.5rem;
  }
  
  h2 {
    font-size: 3.5rem;
  }
  
  h3 {
    font-size: 2rem;
  }
  
  p.lead {
    font-size: 1.15rem;
    font-weight: 400;
  }
  
  .subheading {
    text-transform: uppercase;
    font-weight: 500;
    @include heading-font;
    font-size: 1.5rem;
  }
  
  .social-icons {
    a {
      display: inline-block;
      height: 3.5rem;
      width: 3.5rem;
      background-color: $gray-700;
      color: $white !important;
      border-radius: 100%;
      text-align: center;
      font-size: 1.5rem;
      line-height: 3.5rem;
      margin-right: 1rem;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        background-color: $primary;
      }
    }
  }
  
  .dev-icons {
    font-size: 3rem;
    .list-inline-item i {
      &:hover {
        color: $primary;
      }
    }
  }