//00b4fb
.bg-primary {
    background-color: $primary !important;
  }
  
  .text-primary {
    color: $blue !important;
  }
  
  a {
    color: $primary;
    &:hover,
    &:focus,
    &:active {
      color: darken($white, 15%);
    }
  }